<template>
  <div class="product-radio">
    <label
      v-for="item in mappedItems"
      :key="item.name"
      class="product-radio__label"
      :for="item.id"
    >
      <input
        :id="item.id"
        v-model="input"
        type="radio"
        :value="item.name"
        :disabled="item.disabled"
      />
      <span class="product-radio__text">
        {{ item.name }}
      </span>
      <span
        v-if="item.message"
        class="product-radio__additional"
      >
        {{ item.message }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    disabled: {
      type: Array,
      required: false,
      default: () => [],
    },
    unavailable: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      input: '',
    };
  },
  computed: {
    mappedItems() {
      return this.items.map((item) => {
        // We need a unique number for making ids that don't clash
        const random = [...Array(16)]
          .map(() => (~~(Math.random() * 36)).toString(36))
          .join('');
        const disabled = this.disabled.includes(item);
        const unavailable = this.unavailable.includes(item);
        const message = unavailable
          ? this.$content.moduleSelectVariant.unavailable
          : this.$content.global.noStock;
        return {
          name: item,
          disabled,
          message: disabled ? message : null,
          id: `option-${random}`,
        };
      });
    },
  },
  watch: {
    input(value) {
      this.$emit('update:modelValue', value);
    },
  },
  created() {
    if (this.modelValue) {
      this.input = this.modelValue;
    }
  },
};
</script>

<style lang="scss" scoped>
.product-radio {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: var(--theme-body-font, var(--system-font));
    font-size: 0.9375rem;
    font-weight: 400;

    & + & {
      margin-top: 0.5rem;
    }
  }

  &__text {
    margin-right: 1rem;
  }

  &__additional {
    margin-left: auto;
    color: var(--grey-600);
  }
}
</style>
