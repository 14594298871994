<template>
  <div class="status-page">
    <column-layout
      v-if="loading || (!loading && item?.state === returnStates.CANCELLED)"
      class="status-page__main"
    >
      <page-loading v-if="loading === 'page'" />
      <not-found v-else-if="loading === 'failed'" />
      <cancelled-banner v-else />
    </column-layout>

    <template v-else>
      <oos-banner
        class="status-page__banner"
        :exchanges="item.exchanges"
        :return-key="item.returnKey"
        :customer="item.customer"
      />
      <removed-items-banner
        class="status-page__banner"
        :removed-items="item.removedLineItems"
      />
      <div
        :class="{
          'status-page__header': showReturnsTracking && !showExchangeTracking
        }"
      >
        <div
          class="status-page__header-content"
          :class="{
            'return-tracking-variant': showReturnsTracking && !showExchangeTracking
          }"
        >
          <base-text
            class="status-page__heading"
            tag="h1"
            type="display-xlarge"
          >
            <render-content>
              {{ pageHeading }}
            </render-content>
          </base-text>

          <return-tracking-header v-if="showReturnsTracking && !showExchangeTracking" />
        </div>
      </div>

      <column-layout class="status-page__main">
        <banner
          v-if="item.errors
            && item.errors.returnMethod
            && item.errors.returnMethod.name === 'happy-returns'"
          :heading="contentModuleHappyReturnsDropoffErrorHeading"
          :description="contentModuleHappyReturnsDropoffErrorDescription"
        />

        <banner
          v-if="showQrCodeAlert"
          :heading="contentModuleUspsQrSelectorAlertHeading"
          :description="contentModuleUspsQrSelectorAlertDescription"
        />

        <return-tracking-overview v-if="showReturnsTracking || showExchangeTracking" />

        <base-text
          v-if="multiLabelReady && returnLabel?.carrier"
          tag="h3"
          type="heading"
          class="status-page__main__heading"
        >
          <render-content :data="contentData">
            {{ returnToCarrierBy }}
          </render-content>
        </base-text>

        <return-action
          v-if="!isExpiredHappyReturns
            && !isInStoreReturn
          "
          v-bind="{
          ...item,
          name: item.orderName,
          returnId: item.id,
          returnMethod: computedReturnMethod,
          label,
          labelAddressErrors,
          instructions,
          labelsEnabled,
          qrCodeUrl,
          isFullKeep,
          labelIsProcessing,
          showReturnsTracking,
          }"
          data-testid="return-action"
          @label="setLabel"
          @generate="generateLabel"
          @update="update"
          @createLabelQr="createLabelQrCode"
          @toggleQrAlert="toggleQrAlert"
          @toggleEditCustomerInfo="showEditCustomerInfoModal = !showEditCustomerInfoModal"
        />

        <accordion-card
          v-if="showPackingInstructions"
          class="status-page__accordion status-page__packing-instructions"
          :heading="packingInstructionsHeading"
          :expanded="true"
        >
          <packing-instructions
            :to-return="item.toReturn"
            :to-keep="item.toKeep"
            :return-method="item.returnMethod"
            :return-key="item.returnKey"
            :policy="item.policy"
          />
        </accordion-card>

        <accordion-card
          class="status-page__accordion"
          :heading="contentModuleCustomerInfoHeading"
          :expanded="true"
        >
          <customer-information
            :customer="item.customer"
            :return-id="item.id"
            :address="item.address"
            :can-edit="canEditCustomerAddress"
            :is-modal-active="showEditCustomerInfoModal"
            class="status-page__customer-information"
            :errors="labelAddressErrors"
            @close="showEditCustomerInfoModal = false"
            @update="updateCustomerAddress"
          />
        </accordion-card>

        <template #sidebar>
          <return-summary
            v-if="item.state !== 'expired'"
            :receipt="receipt"
            :totals="item.totals"
            :returning="item.returnedItems"
            :line-items="item.lineItems"
            :removed-line-items="item.removedLineItems"
            :purchasing="item.newItems"
            :exchanges="item.exchanges"
            :has-gift-card="item.hasGiftCard"
            :shop-later-offer="item.shopLater"
            :return="item"
            :returnMethod="computedReturnMethod"
          />

          <shop-later-offer-inline
            v-if="inlineShopLaterOfferVisible"
            :shop-later-record="shopLaterRecord"
            :currency="item.orderCurrency || item.currency"
            :return="item"
            @open-shop-later-modal="openShopLaterModal"
          />
          <shop-later-expired-inline
            v-else-if="showShopLaterExpiredBanner && hasShopLaterFF"
            class="status-page__shop-later-banner"
          />
          <customer-survey
            v-if="showCSAT"
            data-testid="customer-survey"
            :return-key="route?.params.hash"
            :analytics-data="analyticsData"
          />
          <support-section
            :page="statusPage"
            class="status-page__support"
            :style="item.state === 'expired' && 'margin-top: 0'"
          />
          <associated-returns
            :order="item"
            class="status-page__associated"
          />
        </template>
      </column-layout>

      <shop-later-offer-modal
        :is-active="shopLaterModalActive"
        :item="item"
        :inline="hasShopLaterFF"
        :accepted="inlinePCOAccepted"
        @close-modal="shopLaterModalActive = false"
        @set-is-active="shopLaterModalActive = true"
        @refresh="initialize"
      />
    </template>
  </div>
</template>

<script>
import { trackEvent } from '@/js/helpers/trackEvent';

export default {
  beforeRouteEnter(to, from, next) {
    // Checks for a from=referrer, then looks for an internal referrer
    const label = to.query.from ?? from.name ?? 'outside of app or page refresh';

    trackEvent(`referred from ${label}`);

    next((vm) => {
      vm.isInitialView = from?.name === 'review';
    });
  },
};
</script>

<script setup>
import { computed, provide, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router/dist/vue-router';
import { merge } from 'lodash';
import { enablePageScroll } from 'scroll-lock';
import {
  BaseText,
} from '@loophq/design-system';
import { returnStates } from '@/js/constants/returns';
import Returns from '@/js/controllers/return';
import { logError } from '@/js/helpers/errors';
import NotFound from '@/views/404';
import PageLoading from './StatusPage/PageLoading';
import ColumnLayout from '@/components/layout/ColumnLayout';
import CustomerSurvey from '@/views/StatusPage/CustomerSurvey.vue';
import SupportSection from '@/components/app/SupportSection';
import OosBanner from '@/views/StatusPage/OosBanner';
import CancelledBanner from '@/views/StatusPage/CancelledBanner';
import RemovedItemsBanner from '@/views/StatusPage/RemovedItemsBanner';
import ReturnAction from '@/views/StatusPage/ReturnAction';
import PackingInstructions from '@/views/StatusPage/PackingInstructions';
import CustomerInformation from '@/views/StatusPage/CustomerInformation';
import ReturnSummary from '@/views/StatusPage/ReturnSummary';
import ReturnTrackingOverview from '@/views/StatusPage/Tracking/Overview';
import AssociatedReturns from '@/views/StatusPage/AssociatedReturns';
import AccordionCard from '@/views/StatusPage/AccordionCard';
import ReturnTrackingHeader from '@/views/StatusPage/Tracking/Header';
import ShopLaterExpiredInline from '@/views/StatusPage/ShopLaterExpiredInline';
import ShopLaterOfferInline from '@/views/StatusPage/ShopLaterOfferInline';
import ShopLaterOfferModal from '@/views/StatusPage/ShopLaterOfferModal';
import Banner from '@/views/StatusPage/Banner';
import useLabels from '@/js/composables/views/StatusPage/labels';
import useTracking from '@/js/composables/views/StatusPage/tracking';
import useShopLater from '@/js/composables/views/StatusPage/shopLater';
import useReturnMethods from '@/js/composables/views/StatusPage/returnMethods';
import useCustomers from '@/js/composables/views/StatusPage/customer';
import { customerPortalPageNames } from '@/js/constants/segment';
import formatDate from '@/js/helpers/formatDate';

const store = useStore();

const route = useRoute();

const item = ref(null);
const loading = ref('page');
const showEditCustomerInfoModal = ref(false);
const shopLaterModalActive = ref(false);
const inlinePCOAccepted = ref(false);
const statusPage = customerPortalPageNames.STATUS_PAGE;
const analyticsData = ref(store.getters['analytics/getData']);

const {
  contentModuleCustomerInfoHeading,
} = useCustomers();

const {
  contentModuleUspsQrSelectorAlertDescription,
  contentModuleUspsQrSelectorAlertHeading,
  label,
  labelAddressErrors,
  labelsEnabled,
  labelIsProcessing,

  qrCodeUrl,
  showQrCodeAlert,

  createLabelQrCode,
  generateLabel,
  setLabel,
  toggleQrAlert,
} = useLabels(item, loading);

const {
  showExchangeTracking,
  showReturnsTracking,
} = useTracking(item);

const {
  inlineShopLaterOfferVisible,
  showShopLaterExpiredBanner,
  shopLaterRecord,
} = useShopLater(item);

const {
  computedReturnMethod,
  contentModuleHappyReturnsDropoffErrorDescription,
  contentModuleHappyReturnsDropoffErrorHeading,
  instructions,
  isExpiredHappyReturns,
  packingInstructionsHeading,
  showPackingInstructions,
} = useReturnMethods(item, showReturnsTracking, showExchangeTracking);

provide('normalizedReturn', item);
provide('showExchangeTracking', showExchangeTracking);

const settings = computed(() => {
  return store.getters['settings'];
});

const canEditCustomerAddress = computed(() => {
  return item.value?.state === returnStates.OPEN && !item.value?.instantExchangeReceipt?.status;
});

const pageHeading = computed(() => {
  if (item.value?.state === returnStates.EXPIRED) {
    return store.getters['content'].moduleExpired.pageHeading;
  }
  return store.getters['content'].pageReturnStatus.heading;
});

const contentData = computed(() => {
  return {
    carrier: returnLabel.value?.carrier,
    returnBy: formatDate(item.value?.returnBy),
  };
});

const returnToCarrierBy = computed(() => {
  return store.getters['content'].moduleReturnCodeForMultiLabel.returnToCarrierBy;
});

const returnLabel = computed(() => {
  return store.getters['firstMile/label'] ?? null;
});

const receipt = computed(() => {
  return {
    shopNowDiscount: item.value?.shopNowDiscount,
    shopNowDiscountPercentage: item.value?.shopNowDiscountPercentage,
    handlingFee: item.value?.handlingFee,
    bonusUsed: item.value?.bonusUsed,
    returnedItemCredit: item.value?.returnedItemCredit,
    newItemTotal: item.value?.newItemTotal,
    subtotal: item.value?.subtotal,
    tax: item.value?.tax,
    total: item.value?.total,
    returnedItemTotalDiscount: item.value?.returnedItemTotalDiscount,
    returnedItemTax: item.value?.returnedItemTax,
    newItemTax: item.value?.newItemTax,
    returnedItemSubtotal: item.value?.returnedItemSubtotal,
    newItemSubtotal: item.value?.newItemSubtotal,
    shippingTotal: item.value?.shippingTotal,
    exchangeOrderShippingTotal: item.value?.exchangeOrderShippingTotal,
    dutiesTotal: item.value?.dutiesTotal,
    amountToAuthorize: item.value?.instantExchangeReceipt?.status === 'authorized' ? item.value?.instantExchangeReceipt?.amount?.value : null,
    amountToCharge: item.value?.instantExchangeReceipt?.status === 'authorized' ? item.value?.instantExchangeReceipt?.amount?.auth : null,
    currency: item.value?.currency,
    presentmentCurrency: item.value?.presentmentCurrency,
    presentmentTotal: item.value?.presentmentTotal,
    taxesIncluded: item.value?.taxesIncluded,
    giftCardBonus: item.value?.giftCardBonus,
    isFlagged: item.value?.isFlagged,
    returnedItemFee: item.value?.returnedItemFee,
    currencies: item.value?.currencies,
    splitRefundsBreakdown: item.value?.splitRefundsBreakdown,
    perProductHandlingFee: item.value?.perProductHandlingFee,
    policyHandlingFee: item.value?.policyHandlingFee,
  };
});

const isFullKeep = computed(() => {
  return item.value?.lineItems?.length > 0 ? item.value?.lineItems?.every(listItem => listItem.outcome === 'keep') : false;
});

const isInStoreReturn = computed(() => {
  return item.value?.isInStoreReturn;
});

const multiLabelReady = computed(() => {
  return settings.value?.multiLabelsAllowed
    && store.getters['firstMile/labels']?.length > 1;
});

const showCSAT = computed(() => {
  return analyticsData.value?.session !== null && settings.value?.showCsatSurvey;
});

/**
 * Methods
 */
const initialize = async () => {
  loading.value = 'page';
  enablePageScroll();

  if (store?.state.edits.active) {
    item.value = store?.state.edits.return;
    loading.value = null;
    return;
  }

  try {
    item.value = await Returns.get(route.params.hash);
    loading.value = null;

    if (item.value?.labels?.length > 0) {
      store.commit('firstMile/setShippingLabel', { labels: item.value.labels });
    }

    if (item.value.currencies) {
      const { currencies: { shopCurrency, orderCurrency, orderCurrencyExchangeRate, useOrderCurrency } } = item.value;
      store.dispatch('currencies/setData', {
        shop_currency: shopCurrency,
        order_currency: orderCurrency,
        order_currency_exchange_rate: orderCurrencyExchangeRate,
        use_order_currency: useOrderCurrency,
      });
    }

    if (
      item.value.needsLabel &&
      !item.value.returnMethod
    ) {
      await generateLabel();
    } else if (
      item.value.outcome === 'donate' &&
      item.value.prepaidLabels?.length &&
      !item.value.label &&
      !item.value.returnMethod
    ) {
      await generateLabel();
    } else if (
      item.value?.errors?.returnMethod?.name === 'happy-returns' &&
      item.value?.errors?.returnMethod?.error
    ) {
      await generateLabel();
    } else {
      if (item.value.label) {
        store.commit('firstMile/setShippingLabel', {
          labelError: false,
          labelAddressErrors: null,
          ...item.value.label,
        });
      } else {
        store.commit('firstMile/setShippingLabel', item.value.label);
      }
    }
  } catch (error) {
    logError(error);
    loading.value = 'failed';
  }

  trackEvent('status page loaded');
};

const updateCustomerAddress = ({ address }) => {
  item.value.address = merge(item.value.address, address);
  initialize();
};

const update = (data) => {
  item.value = merge({}, item.value, data);
};

const openShopLaterModal = () => {
  shopLaterModalActive.value = true;
};

/**
 * On Created
 */
initialize();

</script>

<style lang="scss" scoped>
$block: '.status-page';

#{$block} {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  color: var(--grey-800);
  margin: 0 auto;

  &__banner {
    margin-bottom: var(--spacing-600);
  }

  &__shop-later-banner {
    margin: 0 var(--spacing-100) var(--spacing-600) var(--spacing-100);
  }

  &__header {
    background: var(--grey-100);
    width: 100%;
    height: 100%;
    color: var(--grey-800);
    margin: 0 auto;
    padding-bottom: var(--spacing-600);
  }

  &__header-content {
    padding: var(--spacing-400) 0;
    max-width: calc(var(--max-page-width) + (var(--spacing-900) * 2));
    flex-grow: 1;
    width: 100%;
    height: 100%;
    color: var(--grey-800);
    margin: 0 auto;

    @media screen and (min-width: $break-small) {
      padding: var(--spacing-600) var(--spacing-900) 0 var(--spacing-900);
    }

    &.return-tracking-variant {
      .display-xlarge {
        margin-bottom: var(--spacing-500);
      }
    }
  }

  &__heading {
    font-weight: 600;
  }

  &__main,
  &__variant-states {
    padding: var(--spacing-600) var(--spacing-900);
    max-width: calc(var(--max-page-width) + (var(--spacing-900) * 2));
    flex-grow: 1;
    width: 100%;
    height: 100%;
    background: white;
    color: var(--grey-800);
    margin: 0 auto;

    * + * {
      margin-top: var(--spacing-400);
    }
  }

  &__pickup-faq {
    margin-top: 0;
  }

  &__support,
  &__associated {
    margin-top: var(--spacing-600);

    :deep(.support-section__text) {
      color: #000;
    }
  }
}

@media screen and (max-width: $break-small) {
  #{$block} {
    padding: var(--spacing-200) var(--spacing-300);

    &__customer-information {
      padding: var(--spacing-400) var(--spacing-500);
    }

    &__header_content {
      padding: 0;
    }

    &__main {
      padding: 0;
    }
  }
}

@media screen and (max-width: $break-tiny) {
  #{$block} {
    padding: var(--spacing-200) 0;

    &__shop-later-banner {
      margin: 0 var(--spacing-300);
    }

    &__heading {
      margin: var(--spacing-500) var(--spacing-400);
    }

    &__header-content {
      padding: 0;
    }

    &__main {
      * {
        border-radius: 0;
        border-right: none;
        border-left: none;
        padding: var(--spacing-400);
      }

      * + * {
        margin-top: var(--spacing-300);
      }

      &__heading span {
        padding: 0;
      }
    }

    &__accordion {
      padding: 0;
    }
  }
}

@media screen and (width <= 680px) {
  #{$block} {
    &__header {
      padding-bottom: 0;
    }
  }
}
</style>
