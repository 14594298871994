import { locations } from './locations';

const returnedItem = {
  'id': 0,
  'created_at': '2020-06-16 20:55:57',
  'updated_at': '2020-06-16 20:56:06',
  'fulfilled_at': '2020-06-16 20:55:35',
  'order_id': 2692,
  'provider_line_item_id': 5170291769413,
  'product_id': 4263057391685,
  'variant_id': 30747124432965,
  'sku': '',
  'currency': 'USD',
  'price': 2000,
  'title': 'Loop T-shirt',
  'variant_title': 'Large / White',
  'returned_at': null,
  'image': '/img/loop-tshirt.jpg',
  'product_type': 'Shirts',
  'return_id': null,
  'exchange_id': null,
  'refunded_at': null,
  'weight': 0,
  'grams': 0,
  'restocked': 0,
  'vendor': 'Loop',
  'fulfillment_service': 'manual',
  'location_id': 18323472453,
  'product_exists': true,
  'barcode': '',
  'inventory_item_id': null,
  'taxable': 0,
  'tax_code': null,
  'discount': 0,
  'discounted_price': 2000,
  'tax': 150,
  'option1': 'Large',
  'option2': 'Blue',
  'option3': '',
  'status': null,
  'returnType': 'credit',
  'resolution': 'return',
  'outcome': 'default'
};

const returnedItemTotals = {
  'item_net': 2400,
  'item_discount': 0,
  'item_tax': 180,
  'item_total': 2580
};

const newItemTotals = {
  'item_net': 500,
  'item_discount': 0,
  'item_tax': 38,
  'item_total': 538
};

const totals = {
  'returned_items': {
    '0': returnedItemTotals
  },
  'new_items': {
    '0': newItemTotals
  },
  'handling_fee': 0,
  'bonus_used': 0,
  'returned_item_tax': -750,
  'returned_item_total_discount': 0,
  'returned_item_subtotal': -10000,
  'returned_item_credit': 10750,
  'new_item_tax': 38,
  'new_item_subtotal': 500,
  'new_item_total': 538,
  'total': 10750,
  'instant_exchange_receipt': null,
  'gift_card_bonus': 0,
};

const policy = {
  'labels_enabled': true,
  'customizations': [
    {
      'lang': 'en',
      'content': {
        'pageConfirmation': {
          'instructions': [
            'Securely pack items. If available, please use original packaging.',
            'Attach your return label to the package.',
            'Drop off the package at your nearest carrier location.',
            'We\'ll issue your refund, gift card, or exchange once we receive your return.'
          ]
        }
      }
    }
  ]
};

const returnAddress = {
  'id': 5275,
  'created_at': '2020-04-01 17:31:24',
  'updated_at': '2020-04-23 14:05:07',
  'name': 'Default Destination',
  'company': null,
  'address1': '536 S Wall St.',
  'address2': '',
  'city': 'Columbus',
  'state': 'Ohio',
  'zip': '43201',
  'country': 'United States',
  'country_code': 'US',
  'phone': '555555555'
};

const address = {
  'id': 5306,
  'created_at': '2020-06-16 20:55:54',
  'updated_at': '2020-06-16 20:55:54',
  'name': 'John Doe',
  'company': null,
  'address1': '1234 Sesame Street',
  'address2': '#101',
  'city': 'Columbus',
  'state': 'Ohio',
  'zip': '43201',
  'country': 'United States',
  'country_code': 'US',
  'phone': '(555) 555-5555'
};

export const label = {
  'id': 3192,
  'created_at': (new Date()).toString(),
  'updated_at': (new Date()).toString(),
  'expires': (new Date()).toString(),
  'return_id': 6114,
  'shipping_provider_id': 1,
  'tracking_number': '9400100897846037872364',
  'label_url': 'https://easypost-files.s3-us-west-2.amazonaws.com/files/postage_label/20200429/48d91b997974408fafd699b403d8ba96.pdf',
  'rate': 274,
  'carrier': 'USPS',
  'service': 'First',
  'status': 'new',
  'refund_status': null,
  'shipment_id': 'shp_f3571d5500f74f4fa9f352d6a8336846',
  'tracker_id': 'trk_1539afb2fd484c1fb67ab0e918a713f5'
};

export const upsLabel = {
  'id': 3198,
  'created_at': (new Date()).toString(),
  'updated_at': (new Date()).toString(),
  'expires': (new Date()).toString(),
  'return_id': 6200,
  'shipping_provider_id': 1,
  'tracking_number': '9400100897846037872364',
  'label_url': 'https://easypost-files.s3-us-west-2.amazonaws.com/files/postage_label/20200429/48d91b997974408fafd699b403d8ba96.pdf',
  'rate': 274,
  'carrier': 'UPS',
  'service': 'First',
  'status': 'new',
  'refund_status': null,
  'shipment_id': 'shp_f3571d5500f74f4fa9f352d6a8336846',
  'tracker_id': 'trk_1539afb2fd484c1fb67ab0e918a713f5'
};

export const labelWithoutCarrier = {
  'id': 3192,
  'created_at': (new Date()).toString(),
  'updated_at': (new Date()).toString(),
  'expires': (new Date()).toString(),
  'return_id': 6114,
  'shipping_provider_id': 1,
  'tracking_number': '9400100897846037872364',
  'label_url': 'https://easypost-files.s3-us-west-2.amazonaws.com/files/postage_label/20200429/48d91b997974408fafd699b403d8ba96.pdf',
  'rate': 274,
  'carrier': '',
  'service': 'First',
  'status': 'new',
  'refund_status': null,
  'shipment_id': 'shp_f3571d5500f74f4fa9f352d6a8336846',
  'tracker_id': 'trk_1539afb2fd484c1fb67ab0e918a713f5'
};

export const oldLabel = {
  'id': 3190,
  'created_at': '2020-04-29 14:00:22',
  'updated_at': '2020-04-29 14:00:22',
  'expires': '2020-08-29 14:00:22',
  'return_id': 6172,
  'shipping_provider_id': 1,
  'tracking_number': '9400100897846037872364',
  'label_url': 'https://easypost-files.s3-us-west-2.amazonaws.com/files/postage_label/20200429/48d91b997974408fafd699b403d8ba96.pdf',
  'rate': 274,
  'carrier': 'USPS',
  'service': 'First',
  'status': 'new',
  'refund_status': null,
  'shipment_id': 'shp_f3571d5500f74f4fa9f352d6a8336846',
  'tracker_id': 'trk_1539afb2fd484c1fb67ab0e918a713f5'
};

const instant_exchange_receipt = {
  'status': 'authorized',
  'amount': {
    'auth': 4000,
    'value': 4000,
    'currency': 'USD'
  }
};

const qrCodeUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASIAAAEiAQAAAAB1xeIbAAABgklEQVR4nO2YQW7EIAxF/y9IsyRSDzBHSY4OB6oUlpWIfheQTmY27SYJE2ARBedJfDmWsU3h7xU+/gEBnepUpzrVqdoplmVBDguBuFqmU3U1QY2SpBnAOAMAjCRJz9TxupqgYolx+XjLHidpz9fVEkUOy5ppjjmxXcq+7IX4mYC434mdWtfqeycUj7uULdumq1b1l6ACSXIAOEULTlhymXO2rktTOe63Me4S9GypV/0VKE6RRCApHy2AaIHwuHXrVv+uFHId72GUQ97DCKNS/pALf1+r+vemVhfPwPowv/mmNFjd9/tQ2zon3AWGwQBw88m6WqBKzinjhBLy8k5Cj/uDKCfJu7T9AZqXXmPuSeWcs/rYlL4K0SZuqFrVX4J6zDHhvskJCyUlcDpXVxPUY44JQMqJx/QZ8pEUyZsQ7gmcsFC+El1NUGO+YRc+JvlV6LogtW1fNZtNsZNDvve1+1Gltwq5qjFgNrkEwn1ZnaWrBep1jvn81nNOpzrVqU5dhfoBNmnDQrksDy0AAAAASUVORK5CYII=';

export const createReturn = (key, data = {}) => {
  return {
    'return_key': key,
    'order_id': 2692,
    'shop_id': 260,
    'order_name': '#1114',
    'outcome': 'default',
    'state': 'open',
    'keep': [],
    'needs_label': true,
    'label': null,
    'prepaid_labels': [],
    'return_code': null,
    'instant_exchange_return_by': null,
    'return_by': '2021-06-04',
    'return_address': returnAddress,
    'locations': [],
    'is_in_store_return': false,
    'has_gift_card': false,
    'customer': {
      'id': 81,
      'email': 'test@test.test',
    },
    address,
    policy,
    'line_items': [
      returnedItem
    ],
    'removed_line_items': [],
    'exchanges': [
      returnedItem
    ],
    totals,
    ...data
  };
};

export const returns = [
  createReturn('normalreturn'),
  createReturn('returnwithlabel', {
    needs_label: false,
    label
  }),
  createReturn('returnwithUPSlabel', {
    needs_label: false,
    upsLabel
  }),
  createReturn('returnwitholdlabel', {
    needs_label: false,
    oldLabel
  }),
  createReturn('returnwithlabelwithoutcarrier', {
    needs_label: false,
    label: labelWithoutCarrier
  }),
  createReturn('returnwithlabelerror'), // This is a special case handled in mirage
  createReturn('prepaidlabel', {
    needs_label: false,
    prepaid_labels: [
      {
        id: 1,
        tracking_code: '123456789faketracker1'
      },
      {
        id: 2,
        tracking_code: '9876543210blastoff'
      }
    ]
  }),
  createReturn('prepaidLabelNoLabel', {
    needs_label: false,
    prepaid_labels: [
      {
        id: 1,
        tracking_code: '123456789faketracker1'
      },
      {
        id: 2,
        tracking_code: '9876543210blastoff'
      }
    ],
    policy: {
      ...policy,
      labels_enabled: false
    }
  }),
  createReturn('returnwithlabelprocessing', {
    needs_label: true,
    label: null,
  }),
  createReturn('keepsome', {
    keep: [1, 2],
    line_items: [
      returnedItem,
      {
        ...returnedItem,
        id: 1,
        outcome: 'keep',
      },
      {
        ...returnedItem,
        id: 2,
        outcome: 'keep',
      },
      {
        ...returnedItem,
        id: 3,
      },
      {
        ...returnedItem,
        id: 4,
      }
    ],
    totals: {
      ...totals,
      returned_items: {
        '0': returnedItemTotals,
        '1': returnedItemTotals,
        '2': returnedItemTotals,
        '3': returnedItemTotals,
        '4': returnedItemTotals,
      },
    }
  }),
  createReturn('keepall', {
    outcome: 'keep',
    keep: [1, 2],
    needs_label: false,
    line_items: [
      {
        ...returnedItem,
        id: 1,
        outcome: 'keep',
      },
      {
        ...returnedItem,
        id: 2,
        outcome: 'keep',
      }
    ],
    totals: {
      ...totals,
      returned_items: {
        '1': returnedItemTotals,
        '2': returnedItemTotals,
      },
    }
  }),
  createReturn('manualreview', {
    outcome: 'review',
    needs_label: false,
    line_items: [
      {
        ...returnedItem,
        id: 1,
      },
      {
        ...returnedItem,
        id: 2,
      }
    ],
    totals: {
      ...totals,
      returned_items: {
        '1': returnedItemTotals,
        '2': returnedItemTotals,
      },
    }
  }),
  createReturn('donate', {
    outcome: 'donate',
    line_items: [
      {
        ...returnedItem,
        id: 1,
      },
      {
        ...returnedItem,
        id: 2,
      }
    ],
    totals: {
      ...totals,
      returned_items: {
        '1': returnedItemTotals,
        '2': returnedItemTotals,
      },
    }
  }),
  createReturn('customerinfoerror'), // This is a special case handled in mirage
  createReturn('outofstockexchange', {
    order_name: '#1234',
    exchanges: [
      {
        ...returnedItem,
        status: {
          status: 'out_of_stock'
        }
      }
    ]
  }),
  createReturn('refundedexchange', {
    order_name: '#1234',
    exchanges: [
      {
        ...returnedItem,
        status: {
          status: 'refunded'
        }
      }
    ]
  }),
  createReturn('creditedexchange', {
    order_name: '#1234',
    exchanges: [
      {
        ...returnedItem,
        status: {
          status: 'credited'
        }
      }
    ]
  }),
  createReturn('qrCodeReturn', {
    label: {
      ...label,
      qr_code_url: qrCodeUrl,
    }
  }),
  createReturn('qrCodeReturnNoLabels', {
    label: {
      ...label,
      qr_code_url: qrCodeUrl,
    },
    policy: {
      ...policy,
      labels_enabled: false
    }
  }),
  createReturn('thirdPartyqrCodeReturnUnknownCarrier', {
    label: {
      ...label,
      carrier: 'Derp Delivery',
      qr_code_url: qrCodeUrl,
    },
    policy: {
      ...policy,
      labels_enabled: false,
      third_party_labels_enabled: true,
    }
  }),
  createReturn('failedneworder', {
    order_name: '#6789'
  }),
  createReturn('returnwithassociatedreturns', {
    returns: [
      {
        'return_key': 'normalreturn',
        'order_name': '#1114',
        'created_at': '2020-10-30 13:44:27',
        'order_line_items': [
          returnedItem,
          returnedItem,
          returnedItem,
        ]
      },
      {
        'return_key': 'returnwithlabel',
        'order_name': '#1114',
        'created_at': '2020-11-03 13:44:27',
        'order_line_items': [
          returnedItem,
          returnedItem,
        ]
      },
    ]
  }),
  createReturn('queuedlabel', {
    needs_label: false,
    label: {
      status: 'queued'
    },
  }),
  createReturn('failedlabel', {
    needs_label: false,
    label: {
      status: 'failed'
    },
  }),
  createReturn('closedreturn', {
    needs_label: false,
    state: 'closed',
    label
  }),
  createReturn('cancelledreturn', {
    needs_label: false,
    state: 'cancelled',
  }),
  createReturn('expiredreturn', {
    needs_label: false,
    state: 'expired',
    label,
  }),
  createReturn('authorizedinstantexchange', {
    instant_exchange_return_by: '2021-09-29 14:00:22',
    return_by: '2021-09-29 14:00:22',
    totals: {
      ...totals,
      instant_exchange_receipt
    }
  }),
  createReturn('chargedinstantexchange', {
    instant_exchange_return_by: '2021-09-29 14:00:22',
    return_by: '2021-09-29 14:00:22',
    totals: {
      ...totals,
      instant_exchange_receipt: {
        ...instant_exchange_receipt,
        status: 'charged'
      }
    }
  }),
  createReturn('authorizedinstantreturn', {
    instant_exchange_return_by: '2021-09-29 14:00:22',
    return_by: '2021-09-29 14:00:22',
    totals: {
      ...totals,
      instant_exchange_receipt
    }
  }),
  createReturn('withstorelocations', {
    locations
  }),
  createReturn('withstorelocationchosen', {
    is_in_store_return: true,
    locations
  }),
  createReturn('withstorelocationsandlabel', {
    is_in_store_return: true,
    locations,
    needs_label: false,
    label
  }),
  createReturn('withreimbursements', {
    totals: {
      ...totals,
      shipping_total: 500,
      duties_total: 1000,
    }
  }),
  createReturn('happyreturn', {
    return_methods: {
      type: 'drop-off',
      name: 'happy-returns'
    }
  }),
  createReturn('happyreturn-error', {
    errors: {
      returnMethod: {
        name: 'happy-returns',
        error: 'no happy returns, just sad ones'
      }
    }
  }),
  createReturn('shopifypos', {
    return_methods: {
      type: 'drop-off',
      name: 'shopify-pos'
    }
  }),
  createReturn('loop-pos', {
    return_methods: {
      type: 'drop-off',
      name: 'loop-pos'
    }
  }),
  createReturn('happyreturn-expired', {
    return_methods: {
      type: 'drop-off',
      name: 'happy-returns'
    },
    state: 'expired',
  }),
  createReturn('inpost', {
    label: {
      ...label,
      carrier: 'inpost',
      qr_code_url: qrCodeUrl,
    },
  }),
  createReturn('asda', {
    label: {
      ...label,
      carrier: 'asda',
      qr_code_url: qrCodeUrl,
    },
  }),
  createReturn('evri', {
    label: {
      ...label,
      carrier: 'evri',
      qr_code_url: qrCodeUrl,
    },
  }),
  createReturn('evrilabel', {
    label: {
      ...label,
      carrier: 'evri',
    },
  }),
  createReturn('removedlineitems', {
    order_name: 'Removed Line Items',
    return_method: {
      type: 'drop-off',
      name: 'happy-returns',
      state: 'new',
    },
    removed_line_items: [
      returnedItem
    ]
  }),
  createReturn('returnwithnoinstructions', {
    policy: {
      labels_enabled: true,
      customizations: [
        {
          lang: 'en',
          content: {
            pageConfirmation: {
              instructions: []
            }
          }
        }
      ]
    }
  }),
  createReturn('packingslip', {
    policy: {
      ...policy,
      packing_slips_enabled: true
    }
  }),
  createReturn('packingslipnoitems', {
    policy: {
      ...policy,
      packing_slips_enabled: true
    },
    line_items: []
  }),
  createReturn('packingslipqr', {
    policy: {
      ...policy,
      packing_slips_enabled: true
    },
    label: {
      ...label,
      qr_code_url: qrCodeUrl,
    }
  }),
  createReturn('packingslipprepaid', {
    policy: {
      ...policy,
      packing_slips_enabled: true
    },
    needs_label: false,
    prepaid_labels: [
      {
        id: 1,
        tracking_code: '123456789faketracker1'
      },
      {
        id: 2,
        tracking_code: '9876543210blastoff'
      }
    ]
  }),
  createReturn('packingslipnolabel', {
    policy: {
      ...policy,
      packing_slips_enabled: true
    },
    needs_label: false,
  }),
];
