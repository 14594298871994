export const orderTrackingEvents = {
  TRACKING_PAGE_LOOKUP_VISIT: 'trackingLookupPageVisit',
  TRACKING_PAGE_VISIT: 'trackingPageVisit',
  ORDER_TRACKING_LOOKUP_SUBMITTED: 'orderTrackingLookupSubmitted',
  RECOMMENDED_PRODUCT_CLICKED: 'reccProductClicked',
  MORE_PRODUCTS_CLICKED: 'moreProductsClicked',
  START_RETURN_CLICKED: 'startReturnClicked',
};

export const customerPortalEvents = {
  SUPPORT_EMAIL_CLICKED: 'supportEmailClicked',
  ELIGIBLE_ITEM_COUNT: 'eligibleItemCount',
  RETURN_ELIGIBILITY_DETAILS: 'returnEligibilityDetails',
  CSAT_SUBMITTED: 'csatSubmitted',
  OPEN_SHIPPING_PROTECTION_CLAIM: 'openShippingProtectionClaim',
  VARIANT_EXCHANGE_SELECTED: 'variantExchangeSelected',
  ADVANCED_EXCHANGE_SELECTED: 'advancedExchangeSelected',
  REPLACE_ITEM_SELECTED: 'replaceItemSelected',
  EXCHANGE_ITEM_CHOSEN: 'exchangeItemChosen',
  SHOP_NOW_PRODUCT_CLICKED: 'shopNowProductClicked',
  SHOP_NOW_PRODUCT_ADDED_TO_CART: 'shopNowProductAddedToCart',
};

export const products = {
  CUSTOMER_PORTAL: 'customer-portal',
  ORDER_TRACKING: 'order-tracking',
  EMBEDDED_PORTAL: 'embedded-portal',
};

export const customerPortalPageNames = {
  ORDER_LOOKUP_PAGE: 'Start Page',
  EXCHANGE_PAGE: 'Item Selection Page',
  CREDIT_PAGE: 'Return or Exchange Options',
  REVIEW_PAGE: 'Summary Page',
  STATUS_PAGE: 'Confirmation Page',
};
