import changeCase from 'change-object-case';
import Labels from '@/js/controllers/labels';
import Returns from '@/js/controllers/return';
import firstMile from '@/js/controllers/firstMile';
import firstMileConstants from '@/js/constants/firstMile';
import methods from '@/js/constants/firstMile';
import { reorderEligibility, refetch } from '@/js/helpers/firstMile';
import { logError } from '@/js/helpers/errors';
changeCase.options = { recursive: true, arrayRecursive: true };

export default {
  namespaced: true,
  state: {
    options: [],
    loading: false,
    returnMethodIsValid: false,
    returnMethod: null,
    returnMethodState: '',
    returnMethodStateRetries: 0,
    label: {},
    labels: [],
  },
  mutations: {
    setOptions(state, options) {
      state.options = options;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setReturnMethodIsValid(state, isValid) {
      state.returnMethodIsValid = isValid;
    },
    setReturnMethod(state, method) {
      state.returnMethod = method;
    },
    setReturnMethodState(state, returnMethodState) {
      state.returnMethodState = returnMethodState;
    },
    setReturnMethodStateRetries(state) {
      state.returnMethodStateRetries = state.returnMethodStateRetries + 1;
    },
    setShippingLabel(state, newLabel) {
      if (Array.isArray(newLabel?.labels)) {
        state.labels = newLabel.labels;
        state.label = newLabel.labels[0] ?? null;
      } else {
        state.label = newLabel;
      }
    },
    setQrCodeUrl(state, { labelId, qrCodeUrl }) {
      const label = state.labels.find(label => label.id === labelId);
      if (label) {
        label.qrCodeUrl = qrCodeUrl;
      }
    }
  },
  actions: {
    addQrCode({ commit }, payload) {
      commit('setQrCodeUrl', payload);
    },
    async getEligibility({ rootState, rootGetters, commit }) {
      commit('setLoading', true);
      commit('setOptions', []);

      const order = rootState.order;
      const lineItems = rootState.return.lineItems;
      const address = rootState.return.address;
      const cart = rootState.return.newItems;
      const isGiftReturn = rootState.return.isGift;
      const isFullKeepItem = rootState.totals?.shippable?.outcome === 'keep';
      const hasShopLaterOffer = rootState.return.hasShopLaterOffer ?? false;
      const fraudScoreCheckedAtTimestamp = rootState.fraudScoreCheckedAtTimestamp;
      const payload = {
        order,
        lineItems,
        address: {
          country_code: address?.countryCode,
          ...address
        },
        cart,
        isGiftReturn,
        isFullKeepItem,
        hasShopLaterOffer,
        fraudScoreCheckedAtTimestamp,
      };

      try {
        const order = rootState.edits?.order ?? rootState.order;
        const options = await firstMile.getEligibility(rootGetters.shop.id, order.id, payload);
        commit('setLoading', false);
        if (options.data) {
          commit('setOptions', options.data);
        }
      } catch (error) {
        logError(error);
      }
    },
    async setReturnMethod({ rootState, rootGetters, commit, dispatch }, method) {
      let returnMethod = null;
      let carrierChoice = null;
      let lineItems = [];

      rootState.return.lineItems.forEach(item => {
        const lineItem = Object.values(rootGetters.order.line_items).find(lineItem => lineItem.id === item.id);
        if (lineItem) {
          const options = {};
          options.id = lineItem.provider_line_item_id;
          options.options = {};
          lineItem.options?.forEach((option, index) => {
            const optionKey = `option${index + 1}`;
            options.options[option.name] = lineItem[optionKey];
          });
          lineItems.push(options);
        }
      });


      if (method.name === methods.HAPPY_RETURNS) {
        returnMethod = {
          type: method.type,
          name: method.name,
          address_id: method.address.id,
          closestAddressIds: method.closest?.map((location) => location.address.id),
          line_items: lineItems,
        };
      } else if (method?.type === 'carrier-choice') {
        const payload = method.payload ?? method.options[0].payload ?? {};
        carrierChoice = {
          type: method.type,
          name: method.name,
          payload: payload,
          slug: payload.service_type,
          line_items: lineItems
        };
      } else if (method.name === methods.LOOP_POS || method.name === methods.SHOPIFY_POS) {

        returnMethod = {
          type: method.type,
          name: method.name,
          provider_location_id: method.location.provider_id,
          line_items: lineItems,
        };
      } else if (method.name === methods.INPOST || method.name === methods.ASDA) {
        carrierChoice = {
          type: method.type,
          name: method.name,
          slug: firstMileConstants.REBOUND_SLUGS[method.name],
          line_items: lineItems
        };
      } else if (methods.REBOUND_SINGLE_CHOICE_METHODS.includes(method.name)) {
        carrierChoice = {
          type: method.type,
          name: method.name,
          slug: firstMileConstants.REBOUND_SLUGS[method.name],
          line_items: lineItems
        };
      } else if (methods.RETURN_METHODS_WITH_LABEL_CHOICES.includes(method.name)) {
        carrierChoice = {
          type: method.type,
          name: method.name,
          slug: method.returnOption,
          lineItems: lineItems
        };
      }

      commit('updateOrder', {
        ...rootGetters.order,
        returnMethod,
        carrierChoice,
      }, { root: true });
      commit('setReturnMethod', returnMethod);
      await dispatch('fees/getFees', rootGetters.order, { root: true });
    },
    refetchReturnMethodState({ state, commit }, { returnKey, interval = 5000, maxRetry = 36 }) {
      const timer = setInterval(() => {
        commit('setReturnMethodStateRetries');
        // clear timer after default 36 retries
        if (state.returnMethodStateRetries > maxRetry) {
          return clearInterval(timer);
        }
        clearInterval(timer);
        return Returns.getReturnMethod(returnKey)
          .then(({ data = null }) => commit('setReturnMethod', changeCase.toCamel(data)))
          .catch((err) => logError(err));
      }, interval);
    },
    refetchShippingLabel({ commit }, { returnId }) {
      const handleError = (err) => {
        logError(err);
        commit('setShippingLabel', {
          labelError: true,
          labelAddressErrors: err.labelAddressErrors ?? null,
        });
      };

      refetch({
        maxRetry: 12,
        handleTimeout: handleError,
        fetch: (timer) => Labels.generate({ id: returnId })
          .then(({ data }) => {
            if (data?.labelUrl || data?.labels?.length > 0) {
              clearInterval(timer);
              commit('setShippingLabel', {
                labelError: false,
                labelAddressErrors: null,
                ...data,
              });
            }
          })
          .catch((err) => handleError(err)),
      });
    },
    updateReturnMethod({ commit }, { returnMethod }) {
      commit('setReturnMethod', returnMethod);
      commit('setReturnMethodState', returnMethod.state);
    },
  },
  getters: {
    options: (state) => (addressZip) => {
      return reorderEligibility(state.options, addressZip);
    },
    loading: (state) => state.loading,
    returnMethodIsValid: (state) => state.returnMethodIsValid,
    returnMethod: (state) => state.returnMethod,
    label: (state) => state.label,
    labels: (state) => state.labels,
    carrier: (state) => {
      // DHL with SEKO uses USPS for return labels
      return state.label?.carrier === firstMileConstants.DHL_SEKO
        ? 'USPS'
        : state.label?.carrier;
    },
  }
};
