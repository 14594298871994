<template>
  <div
    key="product-description"
    class="select-variant__description"
  >
    <base-text
      tag="h2"
      type="heading"
      class="select-variant__description-heading"
    >
      <template v-if="hasProductDetailPageImprovments">
        {{ content.productDescriptionTitle }}
      </template>
      <template v-else>
        <render-content :data="{ title: product.title }">
          {{ content.productDescriptionHeader }}
        </render-content>
      </template>
    </base-text>
    <base-text
      type="body"
      v-html="product.description"
    />
  </div>
</template>

<script>
import { featureFlags } from '@/js/constants/featureFlags';
import { BaseText } from '@loophq/design-system';
import { experiments } from '@/js/constants/experiments';

export default {
  components: {
    BaseText,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    content() {
      return this.$content.moduleSelectVariant;
    },
    hasProductDetailPageImprovments() {
      return this.$store.getters.experimentVariation(featureFlags.PRODUCT_DETAIL_PAGE_IMPROVEMENTS) === experiments.NEW_PDP;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-variant__description {
  background: var(--grey-100);
  padding: var(--spacing-400);

  &-heading {
    margin-bottom: var(--spacing-200);
  }
}
</style>
