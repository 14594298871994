import { computed } from 'vue';
import { returnStates } from '@/js/constants/returns';
import { useStore } from 'vuex';
import firstMile from '@/js/constants/firstMile';

export default function useReturnMethods(item, showReturnsTracking, showExchangeTracking) {
  const store = useStore();

  const contentModuleHappyReturnsDropoffErrorHeading = computed(() => {
    return store.getters['content'].moduleHappyReturnsDropoff.happyReturnsErrorHeading;
  });

  const contentModuleHappyReturnsDropoffErrorDescription = computed(() => {
    return store.getters['content'].moduleHappyReturnsDropoff.happyReturnsErrorDescription;
  });

  const instructions = computed(() => {
    const findByLang = (lang) => (i) => i.lang === lang;
    const customizations = item.value?.policy?.customizations;
    const data = customizations?.find(findByLang(store.state.language)) || customizations?.find(findByLang('en'));

    return data?.content?.pageConfirmation?.instructions;
  });

  const showPackingInstructions = computed(() => {
    return item.value?.outcome !== 'review' && item.value?.state !== returnStates.EXPIRED && (!showReturnsTracking.value && !showExchangeTracking.value);
  });

  const packingInstructionsHeading = computed(() => {
    if (item.value?.returnMethod) {
      return store.getters['content'].modulePackingInstructions.dropOffHeading;
    }

    if (item.value?.toReturn.length === 0 && item.value?.lineItems?.length > 0) {
      return store.getters['content'].modulePackingInstructions.keepHeading;
    }

    return store.getters['content'].modulePackingInstructions.heading;
  });

  const computedReturnMethod = computed(() => {
    return item.value?.returnMethod;
  });

  const isExpiredHappyReturns = computed(() => {
    return item.value?.state === returnStates.EXPIRED && item.value?.returnMethod?.name === firstMile.HAPPY_RETURNS;
  });
  return {
    computedReturnMethod,
    contentModuleHappyReturnsDropoffErrorDescription,
    contentModuleHappyReturnsDropoffErrorHeading,
    instructions,
    isExpiredHappyReturns,
    packingInstructionsHeading,
    showPackingInstructions,
  };
}
