import store from '@/store';
import { experiments } from '@/js/constants/experiments';
import Onstore from '@/js/controllers/onstore';

export const generateOnstoreUrl = (page) => {
  const shopNowUtmRedirect = store.getters['content'].global.shopNowUtmRedirect;
  const productIds = store.state.return.lineItems
    .map(item => item.productId)
    .filter(n => n);
  const productTypes = store.state.return.lineItems
    .map((returningLineItem) => {
      if (typeof store.state.order.line_items === 'object') {
        return store.state.order.line_items[returningLineItem.id]?.product_type ?? null;
      }
      return store.state.order.line_items.find(orderLineItem => orderLineItem.id === returningLineItem.id)?.product_type ?? null;
    })
    .filter(n => n);

  const { loop_subdomain, shop_domain } = store.state.shopContents;
  const { totals, receipt } = store.state.totals;
  let data = {
    loop_return_id: store.state.uuid,
    loop_currency: store.getters['currencies/displayCurrency'],
    currency: store.getters['currencies/displayCurrency'],
    loop_total: convertCurrency(totals.credit.base + totals.credit.bonus.total),
    loop_base: convertCurrency(totals.credit.base),
    loop_credit: convertCurrency(totals.credit.bonus.total),
    loop_discount_percentage: receipt?.shopNowDiscountPercentage,
    loop_domain: window.location.host,
    loop_subdomain: loop_subdomain,
    loop_redirect_url: page ? `${window.location.host}/#/${page}` : `${window.location.host}/#/credit`,
    utm_redirect: shopNowUtmRedirect,
    loop_product_ids: [...new Set(productIds)].join(','),
    loop_product_types: [...new Set(productTypes)].join(','),
    loop_return_key: store.state.return.key,
    loop_experiment_b_eligible: store.getters.experimentVariation(experiments.RETURNS_PORTAL_FLOW_B_EXPERIMENT) === experiments.FLOW_B,
    loop_single_item_eligible: store.state.singleItemEligible,
    loop_embedded_portal: store.state.isEmbeddedPortal,
  };

  const query = Object.entries(data)
    .map(([key, value]) => {
      if (!value) {
        return;
      }

      return `${key}=${encodeURIComponent(value)}`;
    })
    .filter(n => n)
    .join('&');
  return `https://${shop_domain}?${query}`;
};

const convertCurrency = (value) => {
  const exchangeRate = store.getters['currencies/exchangeRate'];
  return Math.round(value * exchangeRate);
};

export const navigateToOnstore = async (returnPath) => {
  await Onstore.trackNavigation(store.state.shopContents.id, 'onstore');

  const onstoreUrl = generateOnstoreUrl(returnPath);

  if (!store.state.isEmbeddedPortal) {
    window.location.href = onstoreUrl;
    return;
  }

  if (store.state.shopContents.shop_domain) {
    window.parent.postMessage({
      action: 'onstore:navigate',
      data: onstoreUrl
    }, `https://${store.state.shopContents.shop_domain}`);
    window.parent.postMessage({
      action: 'onstore:navigate',
      data: onstoreUrl
    }, `https://www.${store.state.shopContents.shop_domain}`);
  }
};
