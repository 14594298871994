<template>
  <div class="exchange-item__image">
    <lazy-img
      v-if="product.variant_image !== undefined"
      alt=""
      :src="variantImage"
      data-testid="product-image"
      @error="imageBackup($event, null)"
    />
    <img
      v-else-if="product.image === null"
      alt=""
      src="/img/icons/no-image.svg"
      data-testid="product-no-image"
    />
    <lazy-img
      v-else
      alt=""
      :src="productImage"
      data-testid="product-backup-image"
      @error="imageBackup($event, null)"
    />
  </div>
</template>

<script setup>
const { imageBackup } = useUtilMethods();
</script>

<script>
import useUtilMethods from '@/js/mixins/util.js';
const { miniImage } = useUtilMethods();
import LazyImg from '@/components/LazyImg';

export default {
  components: {
    LazyImg,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    variantImage() {
      return miniImage(this.product.variant_image, 300);
    },
    productImage() {
      return miniImage(this.product.image, 300);
    },
  },
};
</script>
