export const featureFlags = {
  ENABLE_CUSTOMER_PORTAL_INFORMATION_BANNER: 'enable-customer-portal-information-banner',
  EXCHANGE_ORDER_CHECKOUT: 'exchange-order-checkout',
  INSTANT_REFUNDS: 'instant-refunds',
  RETURN_SUMMARY_NEGATIVE_POSITIVE_IDENTIFIERS: 'return-summary-negative-positive-identifiers',
  SIGNIFYD: 'signifyd',
  SHOP_CASH: 'shop-cash',
  PAYMENTS_STRIPE_3DS2: 'payments-stripe-3ds2',
  RETURNS_PORTAL_HIDE_FEES_ON_CREDIT_TYPE_SELECTION_CARDS: 'returns-portal-hide-fees-on-credit-type-selection-cards',
  OCEANIA_ADDRESS_IMPROVEMENTS_ENABLED: 'oceania-address-improvements-enabled',
  SHOP_NOW_PRICES_CONVERSION: 'shop-now-prices-conversion',
  NEW_SBL_NEGOTIATED_RATE_CARRIERS: 'new-sbl-negotiated-rate-carriers',
  PRODUCT_DETAIL_PAGE_IMPROVEMENTS: 'product-detail-page-pdp-improvements',
  REFACTOR_REBOUND_CARRIER_CHOICE: 'refactor-rebound-carrier-choice'
};
