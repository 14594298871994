<template>
  <div class="product-option">
    <header class="product-option__header">
      <h3 class="product-option__heading">
        {{ option.name }}
      </h3>
      <a
        v-if="hasLink"
        :href="sizeChart.url"
        class="product-option__link"
        target="_blank"
        rel="noopener"
      >
        {{ sizeChart.copy }}
      </a>
      <p
        v-if="active"
        class="product-option__value"
      >
        {{ active }}
      </p>
    </header>
    <section
      v-if="imageSlugs.includes(option.name.toLowerCase())"
      class="product-option__main"
    >
      <product-swatch
        v-model="input"
        :items="option.values"
        :variants="variants"
        :disabled="outOfStock"
        :unavailable="unavailable"
        class="product-option__options"
      />
    </section>
    <section
      v-else
      class="product-option__main"
    >
      <product-radio
        v-model="input"
        :items="option.values"
        :variants="variants"
        :disabled="outOfStock"
        :unavailable="unavailable"
        class="product-option__options"
      />
    </section>
  </div>
</template>

<script>
import architecture from '@/js/architecture';
import ProductRadio from './ProductOptionRadio';
import ProductSwatch from './ProductOptionSwatch';
import { getOutOfStock, getUnavailable } from '@/js/helpers/outOfStock';
import { slugs } from '@/js/constants/imageSlugs';

export default {
  components: {
    ProductRadio,
    ProductSwatch,
  },
  mixins: [architecture],
  props: {
    option: {
      type: Object,
      required: true,
    },
    variants: {
      type: [Array, Object],
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    selected: {
      type: Array,
      required: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      imageSlugs: slugs,
      input: '',
    };
  },
  computed: {
    hasLink() {
      return (
        this.sizeChart &&
        this.sizeChart.url &&
        this.sizeChart.copy &&
        this.sizeChart.option &&
        this.option.name.toLowerCase() === this.sizeChart.option.toLowerCase()
      );
    },
    sizeChart() {
      const {
        sizeChartCopy,
        sizeChartURL,
        sizeChartOption,
      } = this.$content.moduleProductExchange;
      return {
        url: sizeChartURL,
        copy: sizeChartCopy,
        option: sizeChartOption,
      };
    },
    active() {
      const findActive = this.selected.find((option) => option.name === this.option.name);
      if (findActive) {
        return findActive.value;
      }
      return null;
    },
    filteredOptions() {
      if (Array.isArray(this.variants)) {
        // We want to ignore the current option when looking up out of stock/unavailable
        // And just calculate out of stock as if the current one isn't selected
        return this.selected
          .filter((item) => item.name !== this.option.name)
          .reduce((accumulator, current) => {
            return {
              ...accumulator,
              [current.option]: current.value,
            };
          }, {});
      }

      return [];
    },
    outOfStock() {
      if (Array.isArray(this.variants)) {
        return getOutOfStock(
          this.variants,
          this.filteredOptions,
          this.option.position,
          (variant) => {
            if (!this.$shop.use_shopify_inventory) {
              return false;
            }

            return variant.limit - this.getExistingExchanges(variant) <= 0;
          }
        );
      }

      return [];
    },
    unavailable() {
      if (Array.isArray(this.variants)) {
        return getUnavailable(this.variants, this.filteredOptions);
      }

      return [];
    },
  },
  watch: {
    input(value) {
      this.$emit('input', {
        name: this.option.name,
        value,
        option: `option${this.option.position}`,
      });
    },
  },
  created() {
    if (this.active) {
      this.input = this.active;
    }
  },
  methods: {
    getExistingExchanges(variant) {
      return Object.values(this.$store.getters.order.line_items).reduce(
        (accumulator, current) => {
          if (current.new_variant && current.new_variant.id === variant.id) {
            return accumulator + 1;
          }
          return accumulator;
        },
        0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.product-option {
  width: 100%;
  background-color: white;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--grey-100);
    padding: 1rem 1.25rem;
    text-transform: capitalize;
    font-size: 0.9375rem;
  }

  &__heading {
    font-size: 0.9375rem;
    line-height: 1.47;
    font-weight: 600;
  }

  &__link {
    margin-right: auto;
    margin-left: 1rem;
    text-transform: none;

    &:hover {
      color: var(--grey-900);
      transition: color 0.24s ease;
    }
  }

  &__value {
    text-align: right;
  }

  &__main {
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    overflow: auto;
  }
}
</style>
