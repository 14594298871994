<template>
  <div class="product-options">
    <component
      :is="productOptionComponent"
      v-for="(option, index) in sortedOptions"
      :key="option.name"
      :value="option.name"
      class="product-options__option"
      :index="index"
      :option="option"
      :selected="selected"
      :variants="variants"
      @input="update"
    />
  </div>
</template>

<script>
import { featureFlags } from '@/js/constants/featureFlags';
import ProductOption from './ProductOption';
import { getOutOfStock, getUnavailable } from '@/js/helpers/outOfStock';
import ProductOptionNew from './ProductOptionNew';
import { slugs } from '@/js/constants/imageSlugs';
import { experiments } from '@/js/constants/experiments';


export default {
  components: {
    ProductOption,
    ProductOptionNew,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    variants: {
      type: [Array, Object],
      required: true,
    },
    selectedOptions: {
      type: Array,
      required: false,
    },
  },
  emits: ['updated', 'scroll'],
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    shownOptions() {
      // Only show options that have more than one value
      return this.options.filter((option) => {
        return option.values.length > 1;
      });
    },
    hasProductDetailPageUpdatesFeatureFlag() {
      return this.$store.getters.experimentVariation(featureFlags.PRODUCT_DETAIL_PAGE_IMPROVEMENTS) === experiments.NEW_PDP;
    },
    sortedOptions() {
      const options = [...this.shownOptions];

      return options.sort((a, b) => {
        const aIsColor = slugs.includes(a.name.toLowerCase());
        const bIsColor = slugs.includes(b.name.toLowerCase());

        if (aIsColor && !bIsColor) return -1;
        if (!aIsColor && bIsColor) return 1;
        return 0;
      });
    },
    productOptionComponent() {
      return this.hasProductDetailPageUpdatesFeatureFlag === true
        ? ProductOptionNew
        : ProductOption;
    },
  },
  created() {
    if (this.selectedOptions) {
      this.selected = this.selectedOptions;
    }

    // Grab all options that have a single value
    const singleValueOptions = this.options.filter(
      (option) => option.values.length === 1
    );

    // If available, go ahead and update our selected array immediately to preselect these
    if (singleValueOptions) {
      // Mold the data into what the component needs
      const selected = singleValueOptions.map(({ name, position, values }) => {
        return {
          name,
          option: `option${position}`,
          value: values[0],
        };
      });
      // Get a list of the currently selected options
      const selectedNames = selected.map((option) => option.name);
      // Remove any options that we're going to overwrite, and then add the new ones
      this.selected = [
        ...this.selected.filter((option) => !selectedNames.includes(option.name)),
        ...selected,
      ];
    }

    //For PDP updates, automatically select the first options by default
    if (this.hasProductDetailPageUpdatesFeatureFlag === true) {
      // Get default selections considering inventory
      const defaultSelections = this.getDefaultSelections();
      const selectedNames = defaultSelections.map((option) => option.name);

      this.selected = [
        ...this.selected.filter((option) => !selectedNames.includes(option.name)),
        ...defaultSelections,
      ];
    }
  },
  methods: {
    findAvailableValue(option, currentSelections = {}) {
      const isShopTrackingInventory = this.$shop.use_shopify_inventory;

      if (!isShopTrackingInventory) {
        return option.values[0];
      }

      const outOfStock = getOutOfStock(
        this.variants,
        currentSelections,
        option.position,
        (variant) => variant.limit <= 0
      );

      const unavailable = getUnavailable(this.variants, currentSelections);

      const firstAvailableValue = option.values.find(
        (value) => !outOfStock.includes(value) && !unavailable.includes(value)
      );

      return firstAvailableValue || option.values[0];
    },

    getDefaultSelections() {
      const sortedOptions = [...this.options].sort((a, b) => a.position - b.position);
      let currentSelections = {}; // track selections for each variant option

      return sortedOptions.map((option) => {
        const value = this.findAvailableValue(option, currentSelections);

        currentSelections[`option${option.position}`] = value;

        return {
          name: option.name,
          option: `option${option.position}`,
          value,
        };
      });
    },

    update(option) {
      this.selected = [
        ...this.selected.filter((item) => item.name !== option.name),
        option,
      ];

      // Send changes to parent
      this.$emit('updated', this.selected);
    },
  },
};
</script>
