<template>
  <Teleport
    v-if="product"
    to="#app-target"
  >
    <product-modal
      class="shop-now-product"
      :order="order"
      :candidate="product"
      :status="active"
      :image="image"
      :images="product.images"
      :loading="loading"
      :show-product-description="false"
      @close="closeModal"
      @change-image="changeModalImage"
    >
      <div class="shop-now-product__sidebar">
        <main
          v-if="product.title && product.variants && !loading"
          class="shop-now-product__body"
          :class="{
            'no-scroll': !scrollEnabled,
          }"
          data-scroll-lock-scrollable
        >
          <div class="shop-now-product__image">
            <product-images
              class="shop-now-product__img"
              :selected-image="image"
              :images="product.images"
              control-type="dot"
              @full-screen-pressed="scrollEnabled = false"
              @collapse-pressed="scrollEnabled = true"
            />
          </div>
          <div
            v-if="selectedVariant"
            class="shop-now-product__selected-info"
          >
            <base-text
              class="shop-now-product__selected-title"
              tag="span"
              data-testid="shop-now-product-title"
            >
              {{ selectedVariant?.title }}
            </base-text>
            <product-price
              class="shop-now-product__selected-price"
              :product="selectedVariant"
              data-testid="shop-now-product-price"
            />
          </div>
          <product-options
            v-if="product.options && !isDefault"
            :options="options"
            :variants="product.variants"
            @updated="updateSelected"
          />
          <product-description
            v-if="product.description"
            :product="product"
          />
        </main>
        <main
          v-else
          class="shop-now-product__loading"
        >
          Loading...
        </main>
        <footer class="shop-now-product__footer">
          <base-button
            class="shop-now-product__atc"
            :disabled="
              !hasVariant ||
                !selectedVariant ||
                cartText !== content.pageShopNow.optionsSelected
            "
            :activated="adding"
            @click.prevent="addToCart"
          >
            {{ cartText }}
          </base-button>
        </footer>
      </div>
      <template
        v-if="added"
        #overlay
      >
        <pacer-animation
          :product="selectedVariant"
          @finished="closeModal"
        />
      </template>
    </product-modal>
  </Teleport>
</template>

<script setup>
import { ref, computed, onMounted, nextTick, getCurrentInstance, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { each } from 'lodash';
import { BaseButton } from '@loophq/design-system';
import ProductOptions from '@/components/product/ProductOptions';
import ProductModal from '@/components/globals/ProductModal.vue';
import ProductDescription from '@/components/product/ProductDescription';
import PacerAnimation from '@/views/Exchange/PacerAnimation';
import { BaseText } from '@loophq/design-system';
import { formatOptions } from '@/js/helpers/formatProductOptions';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { getFirstCollection, mapActive } from '@/views/Shop/shoputil';
import ProductPrice from '@/components/product/ProductPrice';
import ProductImages from '@/components/product/ProductImages.vue';
import { track } from '@/js/segment';
import { customerPortalEvents } from '@/js/constants/segment';

const store = useStore();
const router = useRouter();
const route = useRoute();
const instance = getCurrentInstance();
const content = computed(() => instance.proxy.$content);

const active = ref(true);
const added = ref(false);
const adding = ref(false);
const loading = ref(false);
const scrollEnabled = ref(true);
const selected = ref([]);
const modalImage = ref(null);
const modalImages = ref([]);

const productId = computed(() => +route.params.productId);

const collectionId = computed(() => +route.params.id);

const shopnowStore = computed(() => store.state.shopnow);

const order = computed(() => store.getters.order);

const totals = computed(() => store.state.totals.totals);

const product = computed(() => {
  const productItem =
    shopnowStore.value.products.find((item) => item.id === productId.value) ?? {};
  let variants = store.state.products.items[productId.value] ?? {};

  if (variants.variants) {
    variants.variants.forEach((variant) => {
      const matchingProductVariant = productItem.variants?.find(
        (pv) => pv.id == variant.id
      );
      variant.price = matchingProductVariant
        ? matchingProductVariant.price
        : variant.price;
    });
  }

  return {
    id: productId.value,
    variants: null,
    ...productItem,
    ...variants,
  };
});

const options = computed(() =>
  formatOptions(product.value.options, product.value.variants ?? [])
);

const image = computed(() => {
  if (hasVariant.value && selectedVariant.value.image) {
    return selectedVariant.value.image;
  }

  return product.value.image?.src ? product.value.image.src : product.value.image;
});

const hasVariant = computed(() => {
  if (product.value.options && selectedVariants.value && product.value.variants) {
    return selectedVariants.value.length === 1 || product.value.variants.length === 1;
  }

  return false;
});

const selectedVariants = computed(() => {
  if (product.value.variants?.length) {
    return product.value.variants.filter((variant) => {
      // Check that every option in the selected array matches the options in
      // this variant
      return (
        selected.value.every((item) => variant[item.option] === item.value) ||
        product.value.variants.length === 1
      );
    });
  }
  return null;
});

const selectedVariant = computed(() => {
  if (hasVariant.value) {
    return selectedVariants.value[0];
  }
  return null;
});

const inCart = computed(() => {
  let count = 0;
  if (order.value) {
    each(order.value.line_items, (item) => {
      if (item.new_variant && selectedVariant.value) {
        if (item.new_variant.id === selectedVariant.value.id) {
          count++;
        }
      }
    });
    if (order.value.cart) {
      order.value.cart.forEach((item) => {
        if (selectedVariant.value) {
          if (item.id === selectedVariant.value.id) {
            count++;
          }
        }
      });
    }
  }
  return count;
});

const cartText = computed(() => {
  if (selectedVariant.value && selectedVariant.value.limit <= 0 + inCart.value) {
    return content.value.pageShopNow.optionsSoldOut;
  }

  if (
    selectedVariant.value &&
    selectedVariant.value.price > totals.value?.available &&
    !order.value.stripe
  ) {
    return content.value.pageShopNow.optionsNoCredit;
  }

  // Messaging for when we have a valid amount of options, but there's no variant
  if (product.value.variants?.length > 1 && hasVariant.value && !selectedVariant.value) {
    return content.value.pageShopNow.optionsNotAvailable;
  }

  return content.value.pageShopNow.optionsSelected;
});

const isDefault = computed(() => {
  if (!product.value.options) return true;

  const count = product.value.options.reduce((accumulator, current) => {
    return accumulator + current.values.length;
  }, 0);

  return count <= 1;
});

const analytics = computed(() => {
  return store.getters['analytics/getData'] ?? {};
});

const closeModal = () => {
  enablePageScroll();
  active.value = false;

  if (collectionId.value) {
    return router.push({
      name: 'shop',
      params: {
        id: collectionId.value,
      },
    });
  }

  return router.go(-1);
};

const updateSelected = (value) => {
  selected.value = value;
};

const addToCart = async () => {
  track(customerPortalEvents.SHOP_NOW_PRODUCT_ADDED_TO_CART, {
    ...analytics.value,
    product: productId.value,
  });

  adding.value = true;
  const cart = order.value.cart
    ? [...order.value.cart, selectedVariant.value]
    : [selectedVariant.value];

  store.commit('updateCart', cart);
  store.commit('return/addNewItem', {
    ...selectedVariant.value,
    variantId: selectedVariant.value?.id,
    exchangeType: 'storefront',
  });

  if (order.value.hasShoppingCart) {
    added.value = true;
    return;
  }

  try {
    const { receipt, errors } = await store.dispatch('totals/get');

    if (errors) {
      throw new Error(errors);
    }

    cart.forEach((item) => {
      if (receipt.newItems[item.id].useStruckPrice) {
        item.price = receipt.newItems[item.id].price;
        item.struckPrice = receipt.newItems[item.id].struckPrice;
        item.shopNowDiscountPercentage = receipt.shopNowDiscountPercentage;
      }
    });

    store.commit('updateOrder', {
      ...order.value,
      cart,
    });

    // Open cart
    router.push('/shop/cart');
    adding.value = false;
    instance.proxy.$trackEvent('add to cart');
  } catch (error) {
    store.commit('launchError', {
      errorType: 'death',
      errorMessage: error[0],
    });

    adding.value = false;
  }
};

const changeModalImage = (image, images) => {
  modalImage.value = image;
  modalImages.value = images;
};

const handleRouteUpdate = async (to, from) => {
  disablePageScroll();
  loading.value = true;

  // Handle navigating between products inside modal
  if (to.params.productId !== from.params.productId) {
    await store.dispatch('shopnow/getCollection', {
      collection: { id: to.params.id },
      payload: { order_id: order.value.id },
    });

    // ensures active state in the nav reflects collection we just loaded
    const initialCollection = getFirstCollection(store.state.tabs);
    const activeCollectionId = to.params.id ? +to.params.id : initialCollection.id;
    store.commit(
      'shopnow/updateNav',
      mapActive(store.state.shopnow.navigation, activeCollectionId)
    );
  }

  await store.dispatch('products/get', {
    id: to.params.productId,
    needsRefreshed: true,
  });

  loading.value = false;
};

watch(
  () => route.params,
  (newParams, oldParams) => {
    if (newParams.productId !== oldParams.productId || newParams.id !== oldParams.id) {
      handleRouteUpdate({ params: newParams }, { params: oldParams });
    }
  }
);

onMounted(async () => {
  track(customerPortalEvents.SHOP_NOW_PRODUCT_CLICKED, {
    ...analytics.value,
    product: productId.value,
  });

  await nextTick(async () => {
    const fromPath = router.options.history.state.back;
    const needsRefreshed = fromPath === '/credit' || fromPath === '/';
    await store.dispatch('products/get', {
      id: productId.value,
      needsRefreshed,
    });
  });

  disablePageScroll();
});
</script>

<style lang="scss" scoped>
.shop-now-product {
  ::-webkit-scrollbar {
    appearance: none;
    width: 12px; /* Wider track */
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #8e95a2;
    border: 3px solid transparent; /* Creates space around the thumb */
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-track {
    margin: 100px; /* Creates 10px space at top and bottom */
  }

  &__image {
    display: none;
  }

  &__sidebar {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 3.5rem;
      background: linear-gradient(to bottom, white, rgba(white, 0.4) 70%, rgba(white, 0));
    }
  }

  &__body {
    padding-top: 3rem;
    flex-grow: 1;
    overflow: auto;
  }

  &__loading {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-weight: 500;
    text-align: left;
    flex-shrink: 0;
    margin-bottom: 0.5rem;

    @include responsive-font(1.5rem, 1.25rem);
  }

  &__price {
    margin: 1rem 0;
    font-size: 0.9375rem;
    flex-shrink: 0;
    color: var(--grey-800);
    opacity: 0.675;
  }

  &__description {
    margin: 1rem 0;
    padding: 0 2rem;
    font-size: 0.9375rem;
    flex-shrink: 0;
    color: $gray-dark;
  }

  &__footer {
    padding: 1rem;
  }

  &__atc {
    width: 100%;
  }

  .no-scroll {
    overflow-y: hidden;
  }

  &__selected-info {
    margin-left: 2.5rem;
    margin-right: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
  }

  &__selected-title {
    font-size: 1.25rem;
    color: $gray-darker;
    line-height: 1.25;
  }

  &__selected-price {
    font-size: 1.25rem;
    color: $gray-darker;
  }

  :deep(.select-variant__description) {
    background: transparent !important;
    padding-left: 2.5rem;
  }

  :deep(.select-variant__description-heading) {
    font-size: 14px;
    font-weight: 600;
    color: $gray-darker;
  }

  :deep(.select-variant__description .body) {
    font-size: 14px;
    color: $gray-darker;
  }
}

@media screen and (width <= 680px) {
  .shop-now-product {
    &__selected-info {
      margin: 0 1.25rem 0 1.5rem;
    }

    &__product {
      margin-left: 0;
      margin-right: 0;
    }

    &__image {
      width: 100%;
      height: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.25rem;
    }

    &__img {
      height: 100%;
      object-fit: cover;
      object-position: center center;
      position: static;
    }

    :deep(.select-variant__description) {
      background: transparent !important;
      padding-left: 1.25rem;
    }
  }
}
</style>
