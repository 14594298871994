import axios from 'axios';

export const availableMetrics = {
  USPS_PICKUP_SHOWED_SCHEDULE: 'showed_schedule_usps_pickup',
  USPS_PICKUP_CLICKED_SCHEDULE: 'clicked_schedule_usps_pickup',
  USPS_PICKUP_SCHEDULED: 'scheduled_usps_pickup',
  USPS_PICKUP_DID_NOT_SCHEDULE: 'did_not_schedule_usps_pickup',
  HAPPY_RETURNS_OFFERED: 'happy_returns_offered',
  INPOST_OFFERED: 'inpost_offered',
  EVRI_OFFERED: 'evri_offered',
  ASDA_OFFERED: 'asda_offered',
  POSTE_ITALIANE_OFFERED: 'poste_italiane_offered',
};

export async function submitMetric(metric, context) {
  try {
    // do not send more than once for the same session
    if (sessionStorage.getItem(metric)) {
      return;
    }
    sessionStorage.setItem(metric, '1');

    // disregard response
    axios.post(
      '/api/v1/metric',
      {
        metric,
        context,
      }
    );
  } catch (e) {
    // do not block on error
  }
}
