<template>
  <label
    :key="item.name"
    class="product-radio__label"
    data-testid="product-radio"
    :class="[{ disabled: item.disabled }, { active: isActive }]"
    :for="item.id"
  >
    <input
      :id="item.id"
      v-model="input"
      class="product-radio__input"
      type="radio"
      :value="item.name"
      :disabled="item.disabled"
    />
    <button
      class="product-radio__overlay"
      :class="[{ disabled: item.disabled }, { active: isActive }]"
      alt=""
      data-testid="product-swatch-overlay"
      @click.prevent="selectItem"
    >
      {{ item.name }}
    </button>
  </label>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
  active: {
    type: String,
    required: false,
    default: '',
  },
});

const emit = defineEmits(['update:modelValue']);

const input = ref('');

const isActive = computed(() => {
  return props.modelValue === props.item.name || props.active === props.item.name;
});

const selectItem = () => {
  if (!props.item.disabled) {
    input.value = props.item.name;
  }
};

watch(input, (newValue) => {
  emit('update:modelValue', newValue);
});

onMounted(() => {
  if (props.modelValue) {
    input.value = props.modelValue;
  }
});
</script>

<style lang="scss" scoped>
.product-radio {
  .fadein-enter-active {
    transition: opacity 0.24s;
  }

  .fadein-enter,
  .fadein-leave-to {
    opacity: 0;
  }

  &__label {
    position: relative;
    min-width: 40px;
    overflow: hidden;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 0.9375rem;
    font-weight: 400;
    border-radius: var(--theme-corners);
    background-color: white;

    &.disabled {
      cursor: not-allowed;
      background-color: #f7f8f8;
      border-color: #d8dbdf;
    }

    &.active {
      border-color: $text-core-primarySelected;
    }
  }

  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  &__overlay {
    cursor: pointer;
    min-width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 8px;
    border: 2px solid #d8dbdf;
    background-color: white;
    transition: border-color 0.2s ease;
    color: $gray-darker;

    &:hover:not(.disabled) {
      border-color: $text-core-primarySelected;
    }

    &.active {
      border-color: $text-core-primarySelected;
    }

    &.disabled {
      background-color: #f7f8f8;
      border-color: #d8dbdf;
      cursor: not-allowed;
      color: #6b7280;

      &::after {
        content: '';
        position: absolute;
        inset: 0;
        background:
          linear-gradient(
            to top right,
            transparent calc(50% - 2px),
            #d8dbdf,
            transparent calc(50% + 2px)
          );
        pointer-events: none;
        z-index: 1;
      }
    }
  }
}
</style>
