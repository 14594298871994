<template>
  <div class="product-swatch">
    <label
      v-for="item in mappedItems"
      :key="item.value"
      class="product-swatch__label"
      data-testid="product-swatch"
      :class="{ disabled: item.disabled }"
      :for="'option-' + item.value"
    >
      <input
        :id="'option-' + item.value"
        v-model="input"
        class="product-swatch__input"
        type="radio"
        :value="item.value"
        :disabled="item.disabled"
      />
      <span
        class="product-swatch__text"
        :class="{ hidden: item.image }"
      >
        {{ item.value }}
      </span>
      <span
        v-if="item.disabled"
        class="product-swatch__disabled"
      >
        {{ item.message }}
      </span>
      <transition name="fadein">
        <img
          v-if="item.image"
          class="product-swatch__image"
          :src="item.image"
          alt=""
          data-testid="product-swatch-image"
        />
      </transition>
    </label>
  </div>
</template>

<script>
import useUtilMethods from '@/js/mixins/util';
const { miniImage } = useUtilMethods();

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    variants: {
      type: [Array, Object],
      requred: false,
      default: () => [],
    },
    disabled: {
      type: Array,
      required: false,
      default: () => [],
    },
    unavailable: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      input: '',
    };
  },
  computed: {
    mappedItems() {
      return this.items.map((item) => {
        const variant =
          Array.isArray(this.variants) &&
          this.variants.find(
            (variant) =>
              variant.option1 === item ||
              variant.option2 === item ||
              variant.option3 === item
          );
        const disabled = this.disabled.includes(item);
        const unavailable = this.unavailable.includes(item);
        const message = unavailable
          ? this.$content.moduleSelectVariant.unavailable
          : this.$content.global.noStock;
        return {
          value: item,
          image: variant && variant.image ? miniImage(variant.image) : null,
          disabled,
          message: disabled ? message : null,
        };
      });
    },
  },
  watch: {
    input() {
      this.$emit('update:modelValue', this.input);
    },
  },
  created() {
    if (this.modelValue) {
      this.input = this.modelValue;
    }
  },
};
</script>

<style lang="scss" scoped>
.product-swatch {
  $parent: &;

  width: 100%;
  display: grid;

  /* autoprefixer: ignore next */
  grid-template-columns: repeat(auto-fill, minmax(96px, 1fr));

  /* autoprefixer: ignore next */
  grid-gap: 0.75rem;

  .fadein-enter-active {
    transition: opacity 0.24s;
  }

  .fadein-enter,
  .fadein-leave-to {
    opacity: 0;
  }

  &__label {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 0.9375rem;
    font-weight: 400;
    padding-bottom: 100%;
    border-radius: var(--theme-corners);

    &.disabled {
      cursor: not-allowed;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: 2px solid var(--theme-primary-color);
      transition: opacity 0.24s ease;
      border-radius: var(--theme-corners);
      box-sizing: border-box;
      opacity: 0;
    }

    &:hover:not(.disabled) {
      &::before {
        opacity: 1;
      }
    }
  }

  &__input {
    @include visually-hidden;

    &:checked {
      ~ #{$parent}__image {
        border-color: var(--theme-primary-color);
      }
    }
  }

  &__text {
    @include skeleton;

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    background-color: var(--grey-200);

    &.hidden {
      @include visually-hidden;
    }
  }

  &__disabled {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(white, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5;
    font-family: var(--theme-body-font);
    font-weight: var(--theme-body-weight);
    text-transform: var(--theme-body-case);
  }

  &__image {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: var(--theme-corners);
    border: 2px solid transparent;
  }
}
</style>
