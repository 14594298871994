<template>
  <transition
    name="module-out"
    appear
  >
    <div class="skeleton-slide">
      <div class="skeleton-heading">
        <skeleton-text
          :lines="1"
          type="heading"
        />
        <skeleton-text
          :lines="1"
          width="20%"
        />
      </div>
      <footer class="skeleton-footer">
        <div class="skeleton-btn">
          <skeleton-text
            :lines="1"
            type="reason"
          />
        </div>
      </footer>
    </div>
  </transition>
</template>

<script>
import SkeletonText from '@/components/feedback/SkeletonText';

export default {
  components: {
    SkeletonText,
  },
  props: {
    reasons: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.skeleton-slide {
  display: flex;
  flex-direction: column;
  height: 100%;

  .skeleton-heading {
    flex-grow: 2;
    margin: 0 var(--spacing-200);
  }

  .skeleton-btn {
    border: 1px solid var(--grey-200);
    padding: var(--spacing-300) calc(var(--spacing-200) + var(--corners));
    border-radius: var(--corners);
    margin-top: var(--spacing-300);
  }

  .skeleton-footer {
    max-height: 150px;
    width: 100%;
    padding: var(--spacing-100) var(--spacing-200);
    background-color: white;
    margin-bottom: var(--spacing-300);
  }
}
</style>
