<template>
  <component :is="shopNowProductComponent" />
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { featureFlags } from '@/js/constants/featureFlags';
import ShopNowProductControl from '@/views/Shop/ShopNowProductControl';
import ShopNowProductNew from '@/views/Shop/ShopNowProductNew';
import { experiments } from '@/js/constants/experiments';


const store = useStore();

const getSelectVariantComponent = () => {
  const hasPdpFlag = store.getters.experimentVariation(featureFlags.PRODUCT_DETAIL_PAGE_IMPROVEMENTS) === experiments.NEW_PDP;
  return hasPdpFlag ? ShopNowProductNew : ShopNowProductControl;
};

const shopNowProductComponent = computed(() => {
  return getSelectVariantComponent();
});
</script>
